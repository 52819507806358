<template>
  <div>
    <div>

<!--      <v-app-bar
          elevation="0"
          color="white darken-2"
      >

        <v-spacer></v-spacer>

        <spinner v-if="loading"></spinner>

        <v-spacer></v-spacer>

        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
        ></v-text-field>

      </v-app-bar>-->

      <v-data-table
          dense
          :show-expand="true"
          :expanded.sync="expanded"
          :headers="headers"
          :items="data"
          :search="search"
          :server-items-length="this.meta.total"
          :options.sync="options"
          class="ml-table"
          :loading="loading" loading-text="Loading... Please wait"

          :footer-props="{
            'items-per-page-options': [15],
            disableItemsPerPage : true,
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right'
          }"
      >

        <template v-slot:expanded-item="{headers, item }">

          <td :colspan="headers.length">
            <table class="table">

              <tbody>

              <tr>
                <td>{{ item.quote_description }}</td>
              </tr>

              </tbody>
            </table>
          </td>

        </template>

        <template v-slot:[`item.quote_description`]="{ item }">
          {{ item.quote_description | truncate(150) }} <span v-if="item.quote_description.length > 150"> ...</span>
        </template>
        <template v-slot:[`item.dates`]="{ item }">

          <div v-for="(i,index) in item.dates" :key="index">
            {{ i.datef }}
          </div>
        </template>
        <!--
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.status != 'Approved' && item.status != 'Rejected'"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          v-if="item.status != 'Approved' && item.status != 'Rejected'"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>

      </template>
       -->
        <template v-slot:[`item.order`]="{ item }">

          <!--
          <v-btn color="blue" dark
              x-small
              :to="{ name: 'vendor.bookings', params: {id: item.id}}"
              class="routerLink"
              target="_blank"
            >View booking</v-btn>
            -->

          <v-btn
              color="blue" dark
              v-if="item.order"
              x-small
              @click="print(item.order)"
          >{{ $t('order.view_order') }}
          </v-btn>
        </template>

        <template v-slot:[`item.actions`]="{ item }">

          <!-- Create offer -->
          <v-chip :color="getColor(item.status)" dark
                  v-if="item.status === 'Open'"
                  @click="item.status === 'Open' ? null:null"
                  small
                  :to="{ name: 'vendor.product.create', query: { quote: item.id }}"

                  class="routerLink ma-2"
          >{{ $t('quote.create_offer') }}
          </v-chip>

          <!-- View offer -->
          <v-chip :color="getColor(item.status)" dark
                  v-if="item.status === 'Offered'"
                  @click="item.status === 'Offered' ? null:null"
                  small
                  :to="{ name: 'vendor.offers', query: { quote: item.id, page : 'Quote' }}"

                  class="routerLink ma-2"
          >{{ $t('quote.view_offer') }}
          </v-chip>

          <!-- Reject offer -->
          <v-chip

              color="red" dark
              v-if="item.status === 'Offered'"
              @click="item.status === 'Offered' ? destroy(item.id,'Rejected'):null"
              small
              class="routerLink ma-2"
          >{{ $t('quote.reject_offer') }}
          </v-chip>

        </template>



        <template v-slot:[`item.product.price`]="{ item }">
          <span v-if="item.product">{{item.product.price | currency}}</span>
        </template>

        <template v-slot:[`item.product.package_discount`]="{ item }">
          <span v-if="item.product">{{item.product.discount}}%</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">

          <v-chip
              v-if="1==2"
              small
              label
              :text-color="getColor(item.status)"
              class="ma-2"

          >{{ item.status }}
          </v-chip>

          <span class="text-xs font-weight-light red--text" :class="getColorClass(item.status)">{{
              item.status
            }}</span>
        </template>


      </v-data-table>

    </div>


    <!-- View Order -->
    <v-dialog
        v-model="showPrint"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="blue-grey darken-2"
        >
          <v-btn
              icon
              dark
              @click="showPrint = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Booking Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="showPrint = false"
            >
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <ViewBooking v-if="this.showPrint" :item=this.selectedItem></ViewBooking>
      </v-card>

    </v-dialog>

  </div>
</template>


<script>

import ViewBooking from '../../components/ViewBooking.vue'

export default {
  middleware: ['auth', 'vendor'],
  components: {
    ViewBooking
  },
  data() {
    return {
      expanded: [],
      showPrint: false,
      selectedItem: null,
      response: [],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        status: ''
      },
      defaultItem: {
        status: ''
      },
      loading: false,
      data: [],
      search: '',
      meta: {
        total: 0
      },
      options: {},
      headers: [

        {text: 'Request Date', value: 'created_at'},
        {text: 'Requested By', value: 'user.name', sortable: false},
       {
          text: 'Request',
          sortable: false,
          value: 'quote_description',
        },
        {text: 'Wedding Date', value: 'dates', sortable: false},

        {text: 'Package', value: 'product.name', sortable: false},
        {text: 'Price', value: 'product.price', sortable: false},
        {text: 'Discount', value: 'product.package_discount', sortable: false},
        {text: 'Order', value: 'order'},
        {text: 'Status', value: 'status'},
        {text: 'Selection', value: 'actions', sortable: false}
      ],
    }
  },
  mounted() {
    this.getQuotes();
  },
  watch: {
    '$route': 'getQuotes',
    options: {
      async handler() {
        await this.getQuotes()
      },
      deep: true,
    },
    search: function () {
      if (this.search == null || this.search == "") {
        this.getQuotes();
      }
      if (this.search.length < 3) return
      this.getQuotes()
    }
  },
  methods: {
    print(item) {
      //console.log(item);
      this.selectedItem = item;
      this.showPrint = true;
    },
    getColor(satus) {
      if (satus === 'Open') return 'cyan'
      else if (satus === 'Offered') return 'orange'
      else if (satus === 'Accepted') return 'green'
      else return 'red'
    },
    getColorClass(satus) {
      if (satus === 'Open') return 'cyan--text'
      else if (satus === 'Offered') return 'orange--text'
      else if (satus === 'Accepted') return 'green--text'
      else return 'red--text'
    },
    getQuotes() {
      this.loading = true;
      let sortDesc = this.options.sortDesc[0];
      let orderby = sortDesc ? '-' + this.options.sortBy[0] : this.options.sortBy[0];

      const params =
          {
            "filter[search_data]": this.search,
            "page": this.$route.query.page ? this.$route.query.page : this.options.page,
            "sort": orderby ? orderby : this.$route.query.sort,
          };

      let uri = '/api/quotes/vendor';
      this.$http.get(uri, {params: params}).then((response) => {
        this.data = response.data.data,
            this.meta = response.data.meta,
            this.loading = false
      });
    },

    destroy(id, status) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.update(id, status);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },

    async update(id, status) {
      this.loading = true;
      this.editedItem.status = status;
      let uri = '/api/quotes/vendor/' + id;
      await this.$http.patch(uri, this.editedItem).then((response) => {
        this.response = response
        this.getQuotes()
        this.loading = false
      });
    },
  },

}
</script>


<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>




